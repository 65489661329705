import { Box, Flex, Stack, useColorModeValue, Button } from "@chakra-ui/react";

import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { COLORS } from "../colors";
import LogoBlack from "../assets/logo.svg";
import LogoWhite from "../assets/logoWhite.svg";

export default function WithSubnavigation({ screen, onQuestionOpen }) {
  return (
    <Box>
      <Flex
        bg={useColorModeValue(
          screen !== undefined ? COLORS.roomWhite : COLORS.white,
          COLORS.dark
        )}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 2 }}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex flex={{ base: 1 }} justify={{ md: "start" }}>
          <img
            src={useColorModeValue(LogoBlack, LogoWhite)}
            height="30"
            width="100"
            alt="logo"
          ></img>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={3}
        >
          {
            <Button
              onClick={() => {
                window.open(
                  "https://api.bumou.space/bumou/privacy-policy",
                  "_blank"
                );
              }}
            >
              {"Privacy Policy"}
            </Button>
          }
          <ColorModeSwitcher />
        </Stack>
      </Flex>
    </Box>
  );
}
