import {
  Box,
  Container,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { FcCheckmark } from "react-icons/fc";

// Replace test data with your own
// const features = Array.apply(null, Array(8)).map(function (x, i) {
//   return {
//     id: i,
//     title: 'Lorem ipsum dolor sit amet',
//     text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.',
//   };
// });

const features = [
  {
    id: 1,
    title: "心情追踪",
    text: "通过追踪您的情绪来理解并改善您的幸福感。反思您的情感并与朋友共享您的旅程。",
  },
  {
    id: 2,
    title: "全球连接",
    text: "与世界各地的朋友建立联系。轻松添加不同地区的朋友，并培养多元化的网络。",
  },
  {
    id: 3,
    title: "针对年龄的社交网络",
    text: "为学生和成人量身打造的体验，确保每个人都有一个安全且相关的环境。",
  },
  {
    id: 4,
    title: "分享时刻",
    text: "通过帖子捕捉并分享生活中的时刻，创建反映您独特故事的个性化时间线。",
  },
  {
    id: 5,
    title: "安全的消息和通话",
    text: "通过安全的消息和通话功能与朋友享受私密对话。保持连接，而不会牺牲您的隐私。",
  },
  {
    id: 6,
    title: "社群支持",
    text: "参与基于共同兴趣的支持性社群和讨论，创造一个积极向上的环境。",
  },
];

export default function GridListWithHeading() {
  return (
    <Box p={4} mt={10}>
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <chakra.h1 fontSize={48} fontWeight={"bold"}>
          Features
        </chakra.h1>
      </Stack>

      <Container maxW={"6xl"} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={"top"}>
              <Box color={"green.400"} px={2}>
                <Icon as={FcCheckmark} />
              </Box>
              <VStack align={"start"}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={"gray.600"}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
