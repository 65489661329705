import React from "react";
import {
  chakra,
  Box,
  useColorModeValue,
  Button,
  Stack,
  Image,
  Text,
  Link,
} from "@chakra-ui/react";
import Navbar from "../../components/navbar";
import Features from "../../components/features";
import Footer from "../../components/footer";
import { COLORS } from "../../colors";
import EditorPicDark from "../../assets/code-dark.png";
import EditorPicLight from "../../assets/code-light.png";

const Home = () => {
  return (
    <Box
      px={8}
      py={5}
      mx="auto"
      pb={20}
      backgroundColor={useColorModeValue(COLORS.white, COLORS.dark)}
    >
      <Navbar />
      <Box
        w={{ base: "full", md: 11 / 12, xl: 9 / 12 }}
        mx="auto"
        textAlign={{ base: "left", md: "center" }}
      >
        <chakra.h1
          mb={6}
          fontSize={{ base: "4xl", md: "6xl" }}
          fontWeight="bold"
          lineHeight="none"
          letterSpacing={{ base: "normal", md: "tight" }}
          color={useColorModeValue("gray.900", "gray.100")}
        >
          <Text
            display={{ base: "block", lg: "inline" }}
            w="full"
            bgClip="text"
            bgGradient="linear(to-r, green.400,purple.500)"
            fontWeight="extrabold"
          >
            Bumou 咘呣
          </Text>
        </chakra.h1>
        <chakra.p
          px={{ base: 0, lg: 24 }}
          mb={6}
          fontSize={{ base: "lg", md: "xl" }}
          color={useColorModeValue("gray.600", "gray.300")}
        >
          欢迎来到Bumou
          咘呣，这是一款旨在增强您的连接、提升您的情绪，并培育全球社区的终极社交应用程序。无论您是学生还是成年人，Bumou都为您提供一个意义深远的交互空间
        </chakra.p>
        <Stack
          direction={{ base: "column", sm: "row" }}
          mb={{ base: 4, md: 8 }}
          spacing={2}
          justifyContent={{ sm: "left", md: "center" }}
        >
          <Link href="https://play.google.com/store/apps/details?id=app.bumoumobile.com">
            <Button
              as="a"
              colorScheme="gray"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              w={{ base: "full", sm: "auto" }}
              mb={{ base: 2, sm: 0 }}
              size="lg"
              cursor="pointer"
            >
              Playstore
            </Button>
          </Link>
          <Link href="https://apps.apple.com/app/id6452385207">
            <Button
              as="a"
              colorScheme="gray"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              w={{ base: "full", sm: "auto" }}
              mb={{ base: 2, sm: 0 }}
              size="lg"
              cursor="pointer"
            >
              Appstore
            </Button>
          </Link>
        </Stack>
      </Box>
      <Box
        w={{ base: "full", md: 10 / 12 }}
        mx="auto"
        mt={20}
        textAlign="center"
      >
        <Image
          w="full"
          rounded="lg"
          shadow="2xl"
          src={useColorModeValue(EditorPicDark, EditorPicLight)}
          alt="Bumou"
        />
      </Box>
      <Features />
      {/* <Testimonial /> */}
      <Footer />
    </Box>
  );
};

export default Home;
